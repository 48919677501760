import React, { useContext } from "react";
import { Fade } from "react-reveal";

import { ThemeContext } from "../../context/themeContext";

import "./introduction.styles.scss";
import introPic from "../../assets/intro.png";
import CustomButton from "../CustomButton";

const Introduction = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="intro container-fluid">
      <Fade bottom duration={2000} distance="20px">
        <div className="row intro-content">
          <div className="intro-desc col-md-8">
            <div className="intro-desc-heading">
              <h2>A Little</h2>
              <h3>about me 😉</h3>
              <p
                style={{
                  color: theme.secondaryText,
                }}
              >
                A visonary and innovative developer with a strong
                entrepreneurial spirit 🔥. Skilled in conceptualizing, designing
                and developing software for business problems 🧐. Dedicated to
                driving innovation with the state-of-the-art industrial and
                technological trends facilitating early adoption 🚀.
              </p>
              <CustomButton
                href="https://github.com/rgala98/resume/blob/master/RESUME_RAHUL_GALA_PROF.pdf"
                target="_blank"
                btnText="Download Resume"
              />
            </div>
          </div>
          <div className="intro-profile col-md-4">
            <div className="intro-profile-picture">
              <img
                loading="lazy"
                src={introPic}
                alt="Rahul Gala Memoji"
                className="profile-picture"
              />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Introduction;
