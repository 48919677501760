import React, { useEffect, useContext } from "react";
import "./App.css";
import { SETTINGS } from "./portfolio";
import AnimatedCursor from "react-animated-cursor";
import ReactGA from "react-ga";
import styled from "styled-components";

import Main from "./Containers/Main";
import { ThemeContext } from "./context/themeContext";

const App = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  useEffect(() => {
    if (SETTINGS.googleTrackingID) {
      ReactGA.initialize(SETTINGS.googleTrackingID, {
        testMode: process.env.NODE_ENV === "test",
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    //Initializing the theme
    changeTheme(localStorage.getItem(SETTINGS.localStorageName) || "dark");
  }, []);

  const MainApp = styled.div`
    background-color: ${theme.body};
    color: ${theme.text};
    a {
      text-decoration: none;
      color: ${theme.accentColor};
      :hover {
        text-decoration: none;
        color: ${theme.accentBright};
        font-weight: bold;
      }
    }
  `;

  const useCursor = SETTINGS.useCustomCursor;

  return (
    <MainApp className="app">
      {useCursor ? (
        <AnimatedCursor
          innerSize={12}
          outerSize={18}
          color={theme.cursorColor}
          outerAlpha={0.2}
          innerScale={0.8}
          outerScale={1.6}
        />
      ) : null}
      <Main />
    </MainApp>
  );
};

export default App;
