import React, { useEffect, useState } from "react";

import { getMosiacProjects } from "../../services/firebase/firebase-service";

import "./projectmosiac.styles.scss";

const ProjectMosiac = () => {

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getAllMosiacProjects();
    return () => {
      setProjects([]);
    };
  }, []);

  const getAllMosiacProjects = async () => {
    const projectsList = await getMosiacProjects();
    setProjects(projectsList);
  };

  return (
    <div className="projectMosiac">
      {projects.map((project) => (
        <a
          href={project.link}
          target="_blank"
          rel="noreferrer"
          className={project.className || ""}
        >
          <img lazy src={project.image} alt={project.shortDesc || ""} />
          <div className="overlay">
            <h1>{project.name}</h1>
            <span>{project.shortDesc}</span>
          </div>
        </a>
      ))}
    </div>
  );
};

export default ProjectMosiac;
