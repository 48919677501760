import React from "react";
import AboutHero from "../../Components/AboutHero";
import TechStack from "../../Components/TechStack";
import CustomButton from "../../Components/CustomButton";

const About = () => {

  return (
    <div>
      <AboutHero />
      <TechStack />
      <div className="goto-projects">
        <h3>lets see</h3>
        <h4>What these superpowers can do</h4>
        <CustomButton href="/projects" btnText="My Projects 👉" />
      </div>
    </div>
  );
};

export default About;
