import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/themeContext";
import ProjectCard from "../ProjectCard";

import { getAllProjects } from "../../services/firebase/firebase-service";

import "./projectsdisplay.styles.scss";

import ClipLoader from "react-spinners/ClipLoader";

const ProjectsDisplay = () => {
  const { theme } = useContext(ThemeContext);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects();
    return () => {
      setProjects([]);
    };
  }, []);

  const getProjects = async () => {
    const projectsList = await getAllProjects();
    setProjects(projectsList);
  };

  return (
    <div className="projects-display">
      {projects.length > 0 ? (
        projects.map((project, index) => (
          <ProjectCard key={project.id} project={project} />
        ))
      ) : (
        <ClipLoader
          color={theme.accentColor}
          loading={true}
          size={150}
          cssOverride={override}
        />
      )}

      {/* projects.length > 0 ? (
        <a
        className="btn"
        style={{
          border: `1px solid ${theme.text}`,
          color: theme.text,
          fontSize: "2rem",
        }}
        href
        onClick={() => getProjects()}
      >
        View More
      </a>
      ) : null */}
    </div>
  );
};

export default ProjectsDisplay;
