import React from "react";

import "./techcard.index.scss";

const TechCard = ({ tech }) => {
  return (
    <div className="tech-card-container">
      <div
        className="tech-card"
        data-tech-name={tech.name}
        style={{ backgroundImage: `url(${tech.image})` }}
      ></div>
      <h3>{tech.name}</h3>
    </div>
  );
};

export default TechCard;
