// Website related settings

//TODO: ADD GOOGLE TRACKING ID
const SETTINGS = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  localStorageName: "rgala-theme",
  // googleTrackingID: "UA-174238252-2",
};

export { SETTINGS };
