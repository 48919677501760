import React from "react";

import "./servicecard.index.scss";

const ServiceCard = ({ textColor, imageUrl, serviceName }) => {
  return (
    <div className="service-card">
      <div className="servcard">
        <div
          className="servcard__picture"
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        >
          <img
            loading="lazy"
            className="servcard__picture--image"
            src={imageUrl}
            alt={serviceName}
          />
        </div>
        <h3 className="servcard__heading" style={{ color: { textColor } }}>
          {serviceName}
        </h3>
      </div>
    </div>
  );
};

export default ServiceCard;
