import React from "react";

import "./projectsintro.styles.scss";

const ProjectsIntro = () => {
  return (
    <section className="projects-intro">
      <h1>Yeah! I work hard 💼</h1>
      <h2>Here are some of my projects.</h2>
      <h3>👇</h3>
    </section>
  );
};

export default ProjectsIntro;
