import { db } from "./firebaseinit";
import { collection, getDocs } from "firebase/firestore";

export const getTechStack = async () => {
  const techstackList = [];
  const techstackSnapshot = await getDocs(collection(db, "techstack"));
  techstackSnapshot.docs.forEach((doc) => {
    techstackList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return sortByKey(techstackList, "order");
};

export const getAllProjects = async () => {
  const projectsList = [];
  const projectsSnapshot = await getDocs(collection(db, "projects"));
  projectsSnapshot.docs.forEach((doc) => {
    projectsList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return sortByKey(projectsList, "order");
};

export const getMosiacProjects = async () => {
  const projectsList = [];
  const projectsSnapshot = await getDocs(collection(db, "mosiacProjects"));
  projectsSnapshot.docs.forEach((doc) => {
    projectsList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return sortByKey(projectsList, "order");
};

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}
