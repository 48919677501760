import React, { useContext } from "react";

import { ThemeContext } from "../../context/themeContext";

import "./custombutton.index.scss";

const CustomButton = ({ href, btnText, target = "_self" }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="btn-cont">
      <a
        className="btn"
        style={{
          border: `1px solid ${theme.text}`,
          color: theme.text,
        }}
        href={href}
        target={target}
      >
        {btnText}
        <span
          className="line-1"
          style={{
            backgroundColor: theme.text,
          }}
        ></span>
        <span
          className="line-2"
          style={{
            backgroundColor: theme.text,
          }}
        ></span>
        <span
          className="line-3"
          style={{
            backgroundColor: theme.text,
          }}
        ></span>
        <span
          className="line-4"
          style={{
            backgroundColor: theme.text,
          }}
        ></span>
      </a>
    </div>
  );
};

export default CustomButton;
