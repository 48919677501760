import React, { useContext } from "react";

import { ThemeContext } from "../../context/themeContext";

import "./hero.styles.scss";
import rahulProfileDark from "../../assets/rahul_profile_dark.png";
import rahulProfileLight from "../../assets/rahul_profile_light.png";
import { Fade } from "react-reveal";

const Hero = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="hero container-fluid">
      <div
        className="row"
        style={{
          boxShadow: `0 10px 10px ${theme.body}`,
        }}
      >
        <Fade bottom duration={2000} distance="40px">
          <div className="hero-greetings col-lg-6">
            <div className="hero-greetings-content">
              <h2 className="hero-greetings-title">Howdy 👋</h2>
              <h1 className="hero-greetings-subtitle">
                I'm <span style={{ color: theme.accentColor }}>Rahul</span>
              </h1>
              <h3 className="hero-greetings-designation">
                UI/UX Designer &nbsp;✏️ &nbsp;&nbsp;|
                &nbsp;&nbsp;Developer&nbsp;💻
              </h3>
              {/*  <h4 className="hero-greetings-current">
                💼
                <span
                  style={{
                    backgroundColor: theme.liveGreen,
                    color: theme.text,
                    boxShadow: `0 2px 10px ${theme.liveGreen}`,
                    animation: `glow 1s infinite alternate`,
                  }}
                >
                  Software Engineer
                </span>
                @&nbsp;
                <a
                  href="https://smartsight.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  Smart Sight Innovations
                </a>
              </h4> */}
            </div>
          </div>
          <div className="hero-profile col-lg-6">
            <img
              src={
                theme.name === "light" ? rahulProfileLight : rahulProfileDark
              }
              alt="Rahul Gala"
              className="hero-profile-img"
            />
          </div>
        </Fade>
      </div>
      <div className="mouse" style={{ borderColor: `${theme.text}` }}></div>
    </div>
  );
};

export default Hero;
