import React from "react";
import Hero from "../../Components/Hero";
import Introduction from "../../Components/Introduction";
import SkillsOverview from "../../Components/SkillsOverview";

import CustomButton from "../../Components/CustomButton";

const Home = () => {
  return (
    <div>
      <Hero />
      <Introduction />
      <div className="goto-projects">
        <h3>curious? 😉</h3>
        <h4></h4>
        <CustomButton href="/about" btnText="Know more about me 👉" />
        <br />
        <br />
        <br />
        <br />
        <h4>OR</h4>
      </div>
      <SkillsOverview />
    </div>
  );
};

export default Home;
