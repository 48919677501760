import React, { useState } from "react";
import { Themes } from "../theme";
// Context has been created
const ThemeContext = React.createContext(false);
// Provider
const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(Themes["dark"]);

  const changeTheme = (theme) => {
    setTheme(Themes[theme]);
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export { ThemeContext, ThemeProvider };
