import React, { useEffect, useState, useContext } from "react";
import TechCard from "../TechCard";

import superRahul from "../../assets/super-rahul.png";

import "./techstack.index.scss";
import { GridLoader } from "react-spinners";
import { ThemeContext } from "../../context/themeContext";

import { getTechStack } from "../../services/firebase/firebase-service";

const TechStack = () => {
  const { theme } = useContext(ThemeContext);

  const [allTechStack, setAllTechStack] = useState([]);

  const [frontendFrameworks, setFrontendFrameworks] = useState([]);
  const [backendFrameworks, setBackendFrameworks] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [designTools, setDesignTools] = useState([]);
  const [cloudFrameworks, setCloudFrameworks] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    getAllTechStack();
    return () => {
      resetAllStack();
    };
  }, []);

  const resetAllStack = () => {
    setAllTechStack([]);
    setFrontendFrameworks([]);
    setBackendFrameworks([]);
    setDatabases([]);
    setDesignTools([]);
    setCloudFrameworks([]);
    setLanguages([]);
  };

  const getAllTechStack = async () => {
    const techList = await getTechStack();
    resetAllStack();
    techList.forEach((tech) => {
      switch (tech.stack) {
        case "frontend":
          frontendFrameworks.push(tech);
          setFrontendFrameworks(frontendFrameworks);
          break;
        case "backend":
          backendFrameworks.push(tech);
          setBackendFrameworks(backendFrameworks);
          break;
        case "database":
          databases.push(tech);
          setDatabases(databases);
          break;
        case "design":
          designTools.push(tech);
          setDesignTools(designTools);
          break;
        case "cloud":
          cloudFrameworks.push(tech);
          setCloudFrameworks(cloudFrameworks);
          break;
        case "language":
          languages.push(tech);
          setLanguages(languages);
          break;
        default:
          break;
      }
    });
    setAllTechStack(techList);
  };
  return (
    <section className="tech-stack">
      <div className="tech-stack-picture">
        <img loading="lazy" src={superRahul} alt="Rahul Gala Super Memoji" />
      </div>
      <h1>My Superpowers 💪</h1>
      {allTechStack?.length > 0 ? (
        <React.Fragment>
          <h2>Frontend</h2>
          <div className="tech-stack--frontend" data-bg="Frontend">
            {frontendFrameworks.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
          <h2>Backend</h2>
          <div className="tech-stack--backend" data-bg="Backend">
            {backendFrameworks.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
          <h2>Database</h2>
          <div className="tech-stack--databases">
            {databases.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
          <h2>Design Tools</h2>
          <div className="tech-stack--designTools">
            {designTools.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
          <h2>Cloud Frameworks</h2>
          <div className="tech-stack--cloudFrameworks">
            {cloudFrameworks.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
          <h2>Languages</h2>
          <div className="tech-stack--languages">
            {languages.map((tech) => (
              <TechCard key={tech.id} tech={tech} />
            ))}
          </div>
        </React.Fragment>
      ) : (
        <GridLoader color={theme.accentColor} loading={true} size={15} />
      )}
    </section>
  );
};

export default TechStack;
