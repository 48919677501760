// Theme Presets

const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#343434",
  dark: "#000000",
  secondaryText: "#7F8DAA",
  accentColor: "#1E84FF",
  accentBright: "#006CFF",
  cursorColor: "252,16,86",
  liveGreen: "#47a148",
  facebookBlue: "#3b5998",
  instagramPink: "#E1306C",
  twitterBlue: "#1DA1F2",
  githubBlack: "#171515",
  dribbblePink: "#EA4C89",
  behanceBlue: "#053EFF",
  linkedInBlue: "#0077B5",
};

const darkTheme = {
  name: "dark",
  body: "#151515",
  text: "#FFFFFF",
  dark: "#000000",
  secondaryText: "#8D8D8D",
  accentColor: "#E3405F",
  accentBright: "#FC1056",
  cursorColor: "0,108,255",
  liveGreen: "#47a148",
  facebookBlue: "#3b5998",
  instagramPink: "#E1306C",
  twitterBlue: "#1DA1F2",
  githubBlack: "#171515",
  dribbblePink: "#EA4C89",
  behanceBlue: "#053EFF",
  linkedInBlue: "#0077B5",
};

export const Themes = { light: lightTheme, dark: darkTheme };
