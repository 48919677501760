import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";
import ServiceCard from "../ServiceCard";

import "./skillsoverview.index.scss";
import CustomButton from "../CustomButton";

import uiuxdesign from "../../assets/ui-ux-design.png";
import webdev from "../../assets/web-deveploment.png";
import mobiledev from "../../assets/mobile-dev.png";
import research from "../../assets/research.png";
import brandstrategy from "../../assets/brand-strategy.png";

const SkillsOverview = () => {
  const { theme } = useContext(ThemeContext);

  const skills = [
    {
      skillId: 0,
      name: "UI UX Design",
      imageUrl: uiuxdesign,
    },
    {
      skillId: 1,
      name: "Web Development",
      imageUrl: webdev,
    },
    {
      skillId: 2,
      name: "Mobile Apps",
      imageUrl: mobiledev,
    },
    {
      skillId: 3,
      name: "Research",
      imageUrl: research,
    },
    {
      skillId: 4,
      name: "Strategize",
      imageUrl: brandstrategy,
    },
  ];

  const mouseEnterSkill = (skill) => {
    const element = document.getElementById("skills-overview");
    element.classList.add("skill-bg--" + skill);
  };

  const mouseLeaveSkill = (skill) => {
    const element = document.getElementById("skills-overview");
    element.classList.remove("skill-bg--" + skill);
  };
  return (
    <section className="skills-overview skill-bg" id="skills-overview">
      <h2>Here's what</h2>
      <h3>I can Do 💪</h3>
      <div className="skills">
        {skills.map((skill) => (
          <div
            key={skill.skillId}
            className="skill-card"
            onMouseEnter={() => mouseEnterSkill(skill.skillId)}
            onMouseLeave={() => mouseLeaveSkill(skill.skillId)}
          >
            <ServiceCard
              textColor={theme.dark}
              imageUrl={skill.imageUrl}
              serviceName={skill.name}
            ></ServiceCard>
          </div>
        ))}
      </div>
      <div className="goto-projects">
        <h3>Now that you know what I can do.</h3>
        <h4>Lets scroll through my work</h4>
        <CustomButton href="/projects" btnText="My Projects 👉" />
      </div>
    </section>
  );
};

export default SkillsOverview;
