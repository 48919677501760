import React from "react";
import ProjectsDisplay from "../../Components/ProjectsDisplay";
import ProjectsIntro from "../../Components/ProjectsIntro";

import "./projects.index.scss";
import ProjectMosiac from "../../Components/ProjectMosiac";

const Projects = () => {
  return (
    <div>
      <ProjectsIntro />
      <ProjectsDisplay />
      <ProjectMosiac />
    </div>
  );
};

export default Projects;
