import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import About from "../Pages/About";
import Home from "../Pages/Home";
import Projects from "../Pages/Projects";
import SplashScreen from "../Pages/SplashScreen";

export const Main = () => {
  return (
    <div className="main">
      <Suspense fallback={<SplashScreen />}>
        <BrowserRouter basename="/">
          <Header />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/projects" exact element={<Projects />} />
            {/* <Route path="/contact" exact element={<SplashScreen />} /> */}
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default Main;
