import React from "react";

import "./projectcard.styles.scss";

const ProjectCard = ({ project }) => {

  return (
    <div
      className="pc-container"
      style={{
        order: project.order,
        justifyContent: project.order % 2 === 0 ? "start" : "end",
      }}
    >
      <a
        href={project.link}
        target="_blank"
        rel="noreferrer"
        className="project-card"
      >
        <div
          className="project-card__image"
          style={{
            backgroundImage: `url(${project.image})`,
          }}
        >
          <div
            className="project-card__image--content"
            data-proj-number={project.projectNumber}
          ></div>
        </div>
        <div
          className="project-card__content"
          data-proj-number={project.projectNumber}
        >
          <h1>{project.name}</h1>
          <span>{project.shortDesc}</span>
        </div>
      </a>
    </div>
  );
};

export default ProjectCard;
