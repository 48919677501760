import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import "./splashscreen.styles.scss";

const SplashScreen = () => {
  const [redirect, setRedirect] = useState(false);
  setTimeout(() => setRedirect(true), 2000);
  return redirect ? (
    <Navigate to="/" />
  ) : //SPLASH ADD HERE
  null;
};

export default SplashScreen;
